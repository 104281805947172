import { Route, Routes } from 'react-router-dom';
import './styles/global-styles.scss';
import { Homepage } from './pages/homepage/Homepage';
import OAuthPage from './pages/oauth-page/OAuthPage';
import { Privacypolicy } from './pages/privacy-policy/Privacypolicy';
import { Terms } from './pages/terms-of-condition/Terms';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/process-oauth" element={<OAuthPage />} />
      </Routes>
    </div>
  );
}

export default App;
