import styled from '@emotion/styled';
import success from '../assets/success.png';
import Button from './Button';
import copy from '../assets/copy.png';
import {copyToClipboard } from '../utils/copyToClipboard';

const SuccessHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items: start;
  gap: 1rem;
  background: #f1f8f5;
  border: 1px solid #95c9b4;
  margin-bottom: 1rem;
`;
const Header = styled.div`
  display: flex;
  align-item: center;
  gap: 2rem;
`;
const Image = styled.img`
  margin-top: 10px;
`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #95c9b4;
  width: max-content;
  border-radius: 4px;
  padding: 1rem;
`;
const Token = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const SuccessCard = ({ token }) => {
  const text = '2yeh4-48dhr-8nsr3-7cbwr-5stef';
  return (
    <>
      <SuccessHeader className="card">
        <Image src={success} alt="success" />
        <div>
          <Header>
            <Title>Token Generated!</Title>
          </Header>

          <Token>
            <Description>{token}</Description>
            {/* <Button success onClick={() => copyToClipboard(token)}>
              <FlexRow>
                <img src={copy} alt="copy" />
                Copy to clipboard
              </FlexRow>
            </Button> */}
          </Token>
        </div>
      </SuccessHeader>
    </>
  );
};
