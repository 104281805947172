export const Privacypolicy = () => {
  return (
    <div>
      When you use our services, you’re trusting us with your information. We understand this is a
      big responsibility and work hard to protect your information and put you in control. We
      collect information to provide better services to all our users — like customers,vendors,items
      etc which are public.and these are highly encrypted.
    </div>
  );
};
