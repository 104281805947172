export const Terms = () => {
  return (
    <div>
      We want to maintain a respectful environment for everyone, which means you must follow these
      basic rules of conduct: comply with applicable laws, including export control, sanctions,
      money laundering, and human trafficking laws respect the rights of others, including privacy
      and intellectual property rights don’t abuse or harm others or yourself (or threaten or
      encourage such abuse or harm) including against children — for example, by misleading,
      defrauding, illegally impersonating, defaming, bullying, harassing, or stalking others;
      hosting or publishing any obscene content; or inciting the violation of applicable laws don’t
      abuse, harm, interfere with, or disrupt the services — for example, by accessing or using them
      in fraudulent or deceptive ways, introducing malware, or spamming, hacking, or bypassing our
      systems or protective measures. When we index the web to bring you search results, we respect
      standard usage restrictions that website owners specify in their websites’ code, so we require
      the same when others use our services
    </div>
  );
};
