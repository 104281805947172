import './Loader.scss';
import theme from '../styles/_theme.scss';

export function Loader({ showAccentColor, style }) {
  return (
    <div className="Loader" style={style}>
      <div className="lds-ellipsis">
        <div
          style={{
            background: showAccentColor ? theme['color-accent'] : '#fff',
          }}
        ></div>
        <div
          style={{
            background: showAccentColor ? theme['color-accent'] : '#fff',
          }}
        ></div>
        <div
          style={{
            background: showAccentColor ? theme['color-accent'] : '#fff',
          }}
        ></div>
        <div
          style={{
            background: showAccentColor ? theme['color-accent'] : '#fff',
          }}
        ></div>
      </div>
    </div>
  );
}
