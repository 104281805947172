import { Loader } from './Loader';

export function Button({ primary, hollow, success, loading, children, style, className, ...rest }) {
  return (
    <button
      {...rest}
      className={
        'button' +
        (primary ? ' primary' : '') +
        (hollow ? ' hollow' : '') +
        (loading ? ' disabled' : '') +
        (success ? ' success' : '') +
        ' ' +
        className
      }
      style={style}
    >
      {loading ? <Loader /> : children}
    </button>
  );
}

export default Button;
