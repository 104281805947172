import { HeaderCard } from '../../components/HeaderCard';
import styled from '@emotion/styled';
import desktop from '../../assets/desktop.png';
import { SuccessCard } from '../../components/SuccessCard';
import { ToastContainer } from 'react-toastify';

export const Homepage = ({ disable }) => {
  const Main = styled.div`
    margin-top: 1rem;
    padding: 1rem;
  `;
  const Title = styled.h2`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  `;
  const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `;
  const MainContent = styled.div`
    display: flex;
    gap: 2rem;
  `;
  return (
    <>
      <ToastContainer />
      <HeaderCard disable={disable} />
      {/* <Main className="card">
        <Title>How to setup/Instruction</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem fringilla suspendisse
          molestie vulputate sed. Adcpascing at facilis is senectus fusce. Lobortns erat consequat
          odio vel praesent hac sem sem tristique enim. Semper rhoncus placerat morbi egestas ut
          vestibulum.
        </Description>
        <MainContent>
          <div>
            <Title>Step 1</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus eleifend
              consequat vitae, purus. Purus diam more.
            </Description>
            <Title>Step 2</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus eleifend
              consequat vitae, purus. Purus diam more.
            </Description>
            <Title>Step 3</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus eleifend
              consequat vitae, purus. Purus diam more.
            </Description>
          </div>
          <div>
            <img src={desktop} alt="desktop" />
          </div>
        </MainContent>
        <Title>Step 4</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas commodo dictumst dui id
          tempus, nunc miollis enim faucibus pharetra.
        </Description>
      </Main> */}
    </>
  );
};
