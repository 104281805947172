import styled from '@emotion/styled';
import qbo from '../assets/qbo.png';
import sp from '../assets/shopify.png';
import { Button } from './Button';
import axios from 'axios';
import { useState } from 'react';

export const HeaderCard = ({ disable }) => {
  const Header = styled.div`
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 1rem;
  `;
  const Image = styled.div`
    background: #fafafa;
    display: flex;
    padding: 35px 18px;
    gap: 16px;
    border-radius: 4px;
  `;
  const CardTitle = styled.h4`
    padding-left: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  `;
  const CardDescription = styled.p`
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
  `;
  const ButtonDiv = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
  `;
  const RightDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
  `;
  const HighlightText = styled.div`
    color: #2c6ecb;
  `;
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    const { data } = await axios.post(`/oauth/qbd-v2-connector/account`, {
      oauthSuccessUrl: encodeURI(
        window.location.protocol + '//' + window.location.host + '/process-oauth?success=true'
      ),
      oauthFailureUrl: encodeURI(
        window.location.protocol + '//' + window.location.host + '/process-oauth?success=false'
      ),
    });
    window.location.assign(data.authorisationUrl);
  };
const onServicexSubmit = async () => {
  setLoading(true);
  const { data } = await axios.get(
    'https://servicex-staging.shoppinpal.com/api/v1/data/webhook/eeed063b-1a9d-4f85-8e8c-25309272e4b4'
  );
  console.log(data);
  window.location.assign(data.authorisationUrl);
};
return (
  <Header className="card">
    <Image>
      <img src={sp} alt="sp" />
      <img src={qbo} alt="qbo" />
    </Image>

    <RightDiv>
      <CardTitle>Shopify QuickBooks Desktop Connector</CardTitle>
      <CardDescription>
        Setup sync for Shopify POS with your QuickBooks Desktop to sync all essential business and
        accounting data—Sales, Purchases, and Cost of Goods Sold to
        <div onClick={onServicexSubmit}> your</div> QuickBooks Desktop software .
      </CardDescription>
      <ButtonDiv>
        <Button primary onClick={onSubmit} loading={loading} disabled={disable}>
          Generate Token
        </Button>
        <HighlightText>Learn more about the Shopify QuickBooks Desktop Connector</HighlightText>
      </ButtonDiv>
    </RightDiv>
  </Header>
);
};
