import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SuccessCard } from '../../components/SuccessCard';
import { Homepage } from '../homepage/Homepage';


function OAuthPage(props) {
  const location = useLocation();
  // const navigate = useNavigate();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const success = searchParams.get('success');
  const token = searchParams.get('token');

  if (token !== null)
    return (
      <main>
        <SuccessCard token={token} />
        <Homepage disable={true} />
      </main>
    );
  return (
    <main className="">
      {success === 'false' ? (
        <>
          <h1>Authentication Failed</h1>
          <h3> Please try following common resolutions for this issue.</h3>
          <ol>
            <li>Open QuickBooks without using “Run as Administrator”</li>
            <ul>
              <li>Ensure other Windows Users aside from the one they are using, are logged out.</li>
              <li>Close QBDT and restart the computer.</li>
              <li>Right click on the shortcut for QuickBooks Desktop and select Properties.</li>
              <li>Click Advanced.</li>
              <li>Ensure the "Run as Admin" is unchecked.</li>
              <li>Click OK to both boxes.</li>
              <li>
                Doubleclick the shortcut to launch QuickBooks (do not right click and run as admin)
              </li>
              <li>Now try to generate the token again.</li>
            </ul>
            <li>Sign into QuickBooks Desktop using the Admin credentials of the company file.</li>
            <ul>
              <li>Open the QuickBooks Company file.</li>
              <li>When prompted, enter the credentials for the Admin profile</li>
              <li>Now try to generate the token again.</li>
            </ul>
            <li>Open your QuickBooks Company file in Single-User mode</li>
            <ul>
              <li>Open the File menu</li>
              <li>
                Confirm if the menu shows “Switch to Single-user Mode” or “Switch to Multi User
                Mode”
              </li>
              <li>If showing “Switch to Single User Mode”, click and follow prompts to change.</li>
              <li>Now try to generate the token again.</li>
            </ul>
            <li>
              Restart Quickbooks and then open only the company file which you want to connect with
              Shopify. Now try to generate the token again.
            </li>
            <li>
              If the above steps don't help to resolve the issue then please reach out to Shopify
              Support.
            </li>
          </ol>
        </>
      ) : (
        <>
          <h1>Authentication in progress</h1>
          <div className="">Redirecting...</div>
        </>
      )}
    </main>
  );
}

export default OAuthPage;
